import React from "react";
import {motion} from 'framer-motion';

const Skills = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref} id="skills">
        <div className="innerpage-wrapper">
            <h2 className="name">Skills</h2>
            <ul>
              <li><span className="highlights">Programming Languages:</span> JavaScript, Java, Python, C#, SQL</li>
              <li><span className="highlights">Web Development:</span> HTML5, CSS3, JavaScript (ES6+), React, Node.js, Express.js, Sequelize, RESTful APIs</li>
              <li><span className="highlights">Databases:</span> SQL, SQLite3, PostgreSQL</li>
              <li><span className="highlights">Other Skills:</span> Git, Web Scraping, Data Collecting, Data Organizing, Mobile Development, React Native</li>
            </ul>
        </div>
      </div>
    );
  });

  export default Skills;