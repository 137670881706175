import React from "react";

const Experience = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref} id="experience">
        <div className="innerpage-wrapper">
            <h2 className="name">Experience</h2>
            <h3>Freelance Software Engineer</h3>
            <h3>Self-Employed | June 2019 – Present</h3>
            <ul>
              <li>Responsible for the implementation of the application's navigation, site <span className="highlights">optimization,</span> code <span className="highlights">performance,</span> and <span className="highlights">design.</span></li>
              <li>Consulted with client to discuss objectives and functionality, illustrate wire frame, and implement functionality.</li>
              <li><span className="highlights">Collaborated with clients</span> to assess project requirements and provide <span className="highlights">ongoing technical support.</span></li>
              <li>Designed and implemented full-stack web applications, mobile apps, databases, and mobile games.</li>
              <li>Optimized application <span className="highlights">performance, security, and scalability</span> based on client feedback.</li>
            </ul>
      </div>
     </div>
    );
  });

  export default Experience;