import React from "react";

const Aboutme = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref} id="aboutme">
        <div className="innerpage-wrapper">
          <h2 className="name">About Me</h2>
          <p>
            Results-driven freelance <span className="highlights">software engineer</span> with <span className="highlights">5+</span> years experience in <span className="highlights">designing, developing, and maintaining</span> full-stack applications for small businesses. Proficient in up-to-date web tools and experienced with popular frameworks like <span className="highlights">React</span>. Proven ability to deliver high-quality projects, <span className="highlights">optimize</span> app performance, and <span className="highlights">collaborate</span> effectively with customers. Seeking a challenging role to implement <span className="highlights">experience and problem solving skills</span> in a new environment with like-minded individuals.
          </p>
          
        </div>
      </div>
    );
  });

  export default Aboutme;