import React from "react";

const Projects = React.forwardRef((props, ref) => {
    return (
      <div className="page1" ref={ref} id="projects">
        <div className="innerpage-wrapper">
            <h2 className="name">Projects</h2>
            <h3>DaeBark Catering</h3>
            <ul>
              <li>A website for a popular Korean catering company built with React.</li>
              <li>Updated weekly for increased site performance and new food listings for increased user engagement.</li>
              <li>Averages over <span className="highlights">800 viewers</span> per month.</li>
            </ul>
            <h3>MAMA Chicken</h3>
            <ul>
              <li>A website for a popular Korean fried chicken restaurant.</li>
              <li><span className="highlights">Ranked #1</span> fried chicken restaurant in NJ.</li>
            </ul>
            <h3>Workout Tracker</h3>
            <ul>
              <li>A mobile app that tracks and saves daily workout routines in a local lightweight SQLite database.</li>
              <li>Built on <span className="highlights">React Native</span> framework using <span className="highlights">Expo Go CLI</span> which allowed quicker implementations and testing of <span className="highlights">hybrid apps (iOS and Android).</span></li>
            </ul>
            <h3>JumperFall</h3>
            <ul>
              <li>A 2D Android mobile game built using <span className="highlights">Java,</span> Gradle build system, and Libgdx framework.</li>
              <li>Integrated player feedback to adjust game mechanics, improving<span className="highlights"> user engagement and retention</span>.</li>
              <li>Designed and packaged 2D graphics/animations into a TextureAtlas resulting in <span className="highlights">optimizing performance by 20%.</span></li>
            </ul>

        </div>
      </div>
    );
  });

  export default Projects;