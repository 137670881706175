import React, { useRef } from 'react';
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar() {
  const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

  return (
    <header>
      <nav ref={navRef} className="navbar">
        {/* <div className="nav-links"> */}
          <a onClick={showNavbar} href="#aboutme">About Me</a>
          <a onClick={showNavbar} href="#experience">Experience</a>
          <a onClick={showNavbar} href="#projects">Projects</a>
          <a onClick={showNavbar} href="#skills">Skills</a>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes/>
          </button>
        {/* </div> */}
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars/>
      </button>
    </header>
  );
}

export default Navbar;