import React from 'react'
import { MdOutlineEmail } from "react-icons/md";
import { RiLinkedinFill } from "react-icons/ri";

function Footer() {
  return (
    <div className='footer'>
        <a href="mailto:calvinkim4@gmail.com" target="_blank"><MdOutlineEmail className="icons"/></a>
        <a href="https://www.linkedin.com/in/calvinkim4/" target="_blank"><RiLinkedinFill className="icons"/></a>
    </div>
  )
}

export default Footer;