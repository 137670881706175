import './App.css';
import React, { useRef, useState } from "react";
import Navbar from './components/Navbar';
import { FaArrowDown } from "react-icons/fa";
import Aboutme from './components/Aboutme';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Footer from './components/Footer';

function App() {
  const divRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);

  const handleMouseMove = (e) => {
    if (!divRef.current || isFocused) return;

    const div = divRef.current;
    const rect = div.getBoundingClientRect();

    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleFocus = () => {
    setIsFocused(true);
    setOpacity(1);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setOpacity(0);
  };

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };
  return (
    <div
      ref={divRef}
      onMouseMove={handleMouseMove}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="spotlight"
        style={{
          opacity,
          background: `radial-gradient(600px circle at ${position.x}px ${position.y}px, rgba(255,255,255,.3), transparent 40%)`,
        }}
      />
      <div className="App">
        <div className='title-div'>
          <h1 className='flicker'>CALVIN KIM</h1>
          <h1 className='flicker'>SOFTWARE ENGINEER</h1>
          <button className='scrollbtn'><a href="#aboutme"><FaArrowDown className='arrow'/></a></button>
        </div>
        
        
      </div>
      <Navbar />
      <Aboutme />
      <Experience/>
      <Projects/>
      <Skills/>
      <Footer />
    </div>
  );
}

export default App;